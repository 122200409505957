import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <>
    <div
      style={{
        marginTop: "3px",
        marginBottom: "0px",
      }}
      class="topLine"
    >
      &nbsp;
    </div>
    <div class="footerOutBox">
      <div
        style={{
          paddingTop: "40px",
        }}
        class="columns is-multiline footerBox pagePadding maxWidth"
      >
        <div class="column is-one-third">
          <div class="footerHeader">Offers</div>
          <div class="footerItem">
            <a href="https://programs.pluralisticnetworks.com/west-landing-page-48cccfee-5b01-4c94-bb72-c8aacacb5f29">
              WEST
            </a>{" "}
          </div>
          <div class="footerItem">
            <a href="https://programs.pluralisticnetworks.com/moods-certifcation">
              NM (for Coaches)
            </a>{" "}
          </div>
          <div class="footerItem">
            <a
              href="https://programs.pluralisticnetworks.com/strategic-marketing"
              activeStyle={{ color: "#d05c25" }}
            >
              Strategic Marketing
            </a>{" "}
          </div>
        </div>
        <div class="column is-one-third">
          <div class="footerHeader">resources</div>

          <div class="footerItem">
            <Link to="/navigator">Mood Navigator</Link>
          </div>
          <div class="footerItem">
            <Link to="/publications">Publications</Link>
          </div>
          {/* <div class="footerItem">Blog</div> */}
        </div>
        <div class="column is-one-third">
          <div class="footerHeader">
            <Link to="/about">about</Link>
          </div>
          <div class="footerHeader">
            <Link to="/contact">contact</Link>
          </div>
        </div>
        <div class="has-text-centered column is-full">
          <span class="legal">
            {" "}
            © 2019, 2020. Pluralistic Networks. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  </>
)

export default Footer
