import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

class Menu extends React.Component {
  render() {
    // let w = "150px"
    // const { isClient } = this.state
    // if (!isClient) {
    //   return <div></div>
    // }
    // w = (window.innerWidth - 900) / 2 + "px"

    return (
      <nav
        class="pnNavigator navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div class=" navbar-brand ">
          <a class="navbar-item" href="/">
            <img src="/svg/icons/icon-pluralistic-logo.svg" />
          </a>

          <a
            role="button"
            class="navbar-burger burger mobMenu"
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navMenu" class=" navbar-menu">
          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="is-arrowless navbar-link">Offers</a>
              <div class="navbar-dropdown">
                <div class="navbar-item">
                  <a
                    href="https://programs.pluralisticnetworks.com/west-landing-page-48cccfee-5b01-4c94-bb72-c8aacacb5f29"
                    activeStyle={{ color: "#d05c25" }}
                  >
                    WEST
                  </a>{" "}
                </div>
                <div class="navbar-item">
                  <a
                    href="https://programs.pluralisticnetworks.com/moods-certifcation"
                    activeStyle={{ color: "#d05c25" }}
                  >
                    NM (for Coaches)
                  </a>{" "}
                </div>

                <div class="navbar-item">
                  <a
                    href="https://programs.pluralisticnetworks.com/strategic-marketing"
                    activeStyle={{ color: "#d05c25" }}
                  >
                    Strategic Marketing
                  </a>{" "}
                </div>
                 <div class="navbar-item">
                  <a
                    href="https://programs.pluralisticnetworks.com/AoP_Sept24"
                  >
                   Ambassador of Possibilities
                  </a>{" "}
                </div>
              </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="is-arrowless navbar-link">Resources</a>
              <div class="navbar-dropdown">
                <div class="navbar-item">
                  <Link to="/navigator" activeStyle={{ color: "#d05c25" }}>
                    Mood Navigator
                  </Link>
                </div>
                <div class="navbar-item">
                  <Link to="/publications" activeStyle={{ color: "#d05c25" }}>
                    Publications
                  </Link>{" "}
                </div>
                <div class="navbar-item">
                  <Link
                    to="/blog"
                    activeStyle={{ color: "#d05c25" }}
                    partiallyActive={true}
                  >
                    Blog
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div class="navbar-item">
              <Link to="/about" activeStyle={{ color: "#d05c25" }}>
                About
              </Link>
            </div>
            <div class="navbar-item">
              <Link to="/contact" activeStyle={{ color: "#d05c25" }}>
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Menu
